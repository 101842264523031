/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: function() {
				$( '.show-results' ).on( 'click', function() {
					$( '.screener-response' ).removeClass( 'hidden' );
				} )
      },
      finalize: function() {
      }
    },
    // Home page
    'home': {
      init: function() {

        // $(window).scroll(function(){
        //   $(".hero__content").css({
        //     "top": "-" + ($(window).scrollTop() / 3) + "px",
        //     "opacity":( 1 - ($(window).scrollTop() * .002))
        //   });
        // });

				// function equalizeHeights(){
				// 	$('.equalheight').equalHeights();
				// }
				//
				// equalizeHeights();
				// window.onresize = equalizeHeights();

      },
      finalize: function() {
      }
    },
    'cssrs_for_communities_and_healthcare': {
      init: function() {
        if(window.location.hash === "" || window.location.hash === "#filter=.") {
          window.location.hash = 'filter=.general-use.english';
        }
      },
      finalize: function() {
				var queryString = window.location.href.split("filter=")[1];
				var queryArray = queryString.split('.');

				function splitFilters(array){
					for(var i = 0; i < array.length; i++){
						if(array[i] !== ''){
							array[i] = "." + array[i];
						}
					}
					for(var i = 0; i < array.length; i++){
						if(array[i] === ''){
							array.splice(i, 1);
						}
					}
					return array;
				};

				var queryArray = splitFilters(queryArray);

				queryArray.forEach( function(element) {
					$(".cs-select > option").each(function() {
						if (this.getAttribute('data-filter-value') == element) {
							this.selected = 'selected';
						}
					});
				});
      }
    },
    'page_template_template_gallery': {
      init: function() {

        var $container = $('#gallery__grid'),
            filters = {};

        function getHashFilter() {
          var hash = location.hash;
          var matches = location.hash.match( /filter=([^&]+)/i );
          var hashFilter = matches && matches[1];
          return hashFilter && decodeURIComponent( hashFilter );
        }

        // make sure a filter is chosen
        if( getHashFilter() != "." ) {

          var isIsotopeInit = false;

          function onHashchange() {
            var hashFilter = getHashFilter();
            if ( !hashFilter && isIsotopeInit ) {
              return;
            }
            isIsotopeInit = true;
            // filter isotope
            $container.isotope({
              itemSelector : '.gallery__item',
              layoutMode: 'fitRows',
              filter: hashFilter
            });
          }

          $(window).on( 'hashchange', onHashchange );
          // trigger event handler to init Isotope
          onHashchange();

          // filter buttons
          $('select').change(function(){
            var $this = $(this);

            // store filter value in object
            // i.e. filters.color = 'red'
            var group = $this.attr('data-filter-group');

            filters[ group ] = $this.find(':selected').attr('data-filter-value');
            // convert object into array
            var isoFilters = [];
            for ( var prop in filters ) {
              isoFilters.push( filters[ prop ] );
            }

						//make array of selects
						var selects = $('.cs-select');
						//for each select
						selects.each( function(select) {
							var filterMatch = false;
							var firstOption;
							var currentSelect = $(this);



							//if select has option with data filter value that matches one of the elements in isoFilters do nothing
							for(i=0; i < isoFilters.length; i++){
								if( currentSelect.children( 'option[data-filter-value="' + isoFilters[i] +'"]' ).length > 0 ) {
									filterMatch = true;
								}
							}

							// if no match was found, set the select's first option to selected
							if(filterMatch === false){
								currentSelect.find(":selected").prop('selected', false);
								currentSelect.children('option').first().selected = 'selected';
							}

						});

						var selector = isoFilters.join('');
            var $grid = $container.isotope({filter: selector});
            $grid.on('arrangeComplete', function(){
              if ( $container.data('isotope').filteredItems.length  ) {
                  $('.empty-state').addClass('hidden');
              } else {
                  $('.empty-state').removeClass('hidden');
              }
            })
            return false;
          });

          // set filter selected option to match query string
					if(window.location.hash !== ""){
						var queryString = window.location.href.split("filter=")[1];
						var queryArray = queryString.split('.');

						function splitFilters(array){
							for(var i = 0; i < array.length; i++){
						    if(array[i] !== ''){
						    	array[i] = "." + array[i];
						    }
						  }
						  for(var i = 0; i < array.length; i++){
						    if(array[i] === ''){
						    	array.splice(i, 1);
						    }
						  }
						  return array;
						};

						var queryArray = splitFilters(queryArray);

						queryArray.forEach( function(element) {
							$(".cs-select > option").each(function() {
								if (this.getAttribute('data-filter-value') == element) {
									this.selected = 'selected';
								}
							});
						});
					}


        } else {
          $container.isotope({
            itemSelector : '.gallery__item',
            layoutMode: 'fitRows',
            filter: '.general-use.english',
          });

          // filter buttons
          $('select').change(function(){
            var $this = $(this);

            // store filter value in object
            // i.e. filters.color = 'red'
            var group = $this.attr('data-filter-group');

            filters[ group ] = $this.find(':selected').attr('data-filter-value');
            // convert object into array
            var isoFilters = [];
            for ( var prop in filters ) {
              isoFilters.push( filters[ prop ] );
            }
            var selector = isoFilters.join('');
            var $grid = $container.isotope({filter: selector});
            $grid.on('arrangeComplete', function(){
              if ( $container.data('isotope').filteredItems.length  ) {
                  $('.empty-state').addClass('hidden');
              } else {
                  $('.empty-state').removeClass('hidden');
              }
            })
            return false;
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
